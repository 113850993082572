<template>
  <div class="panel-window-wrapper" :class="fadeOut ? 'fade-out' : ''">
    <ion-grid class="full-width">
      <ion-row>
        <ion-col></ion-col>
        <ion-col @click="endEaster" size="auto" class="close-button cursor-pointer">&times;</ion-col>
      </ion-row>
      <ion-row class="text-center">
        <ion-col size="6" offset="3">
          <h3>Remembering Dr. Tom Doerr</h3>
        </ion-col>
      </ion-row>
      <ion-row class="margin-top-fourty">
        <ion-col size="6" offset="3">
          <ion-row>
            <ion-col size="4" class="pad-right-thirty"
              ><p><img src="@/assets/Tom-Doerr.jpg" /></p
            ></ion-col>
            <ion-col size="8">
              <p>Dr. Tom Doerr grew up in St. Louis and graduated from Chaminade High School in 1975. He graduated from the University of Chicago-Pritzker School of Medicine in 1983. He began practicing in St. Louis in 1986, when he finished his internal medicine residency at the University of Iowa.</p>
              <p>Always a believer in the potential for technology to support better care for patients, in 1999 Dr. Doerr founded Wellinx, an electronic prescribing system that aimed to reduce the harmful effects of medication interactions while better managing their cost.</p>
              <p>Later Tom and his brother John, one of the most successful Silicon Valley venture capitalists in history, founded Purkinje. The company later became ClearPractice, an electronic medical record system to improve care for patients while reducing the burden of documentation on physicians.</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <p>Frustrated by the barriers placed on adoption of technology by the US healthcare payment system, the Doerr brothers later started Essence Healthcare, a Medicare Advantage Health Plan in their hometown of St. Louis to prove that physicians and insurers could work together, rather than in conflict, to improve the health of the patients they served.</p>
              <p>Tom would go on to describe the model of care that he practiced with his patients - and that Essence Healthcare supported - as the Nine Cs, or the Accountable Primary Care Model. The Nine Cs is based on the work of Dr. Barbara Starfield, a researcher and champion of strong primary care systems worldwide.</p>
              <p>The success of Essence Healthcare led to the formation of Lumeris, as Tom and John set their sights on replicating the Nine Cs model across the US.</p>
              <p>We unexpectedly lost Tom on November 12, 2019, but his spirit, ethos, and sense of humor continue to live on today in innovative products like LumerisEngage, which aim to improve the health of everyone and to support happy and productive lives.</p>
              <p>That spirit is best captured by this single sentence from his former practice website: “All his patients have his cell phone number.”</p>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="pad-twenty"></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events.js";
export default {
  name: "EasterEgg",
  methods: {
    endEaster() {
      EventBus.$emit("endEaster");
    }
  }
};
</script>

<style scoped>
.close-button {
  padding: 15px;
  font-size: 34px;
  color: #969696;
}
.close-button:hover {
  background-color: #dadada;
  color: #969696;
}
.panel-window-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  z-index: 99999999;
}
</style>
