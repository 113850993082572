<template>
  <ion-list mode="ios" lines="none">
    <ion-radio-group :value="orderBy">
      <ion-item class="cursor-pointer border-light">
        <ion-label>Sort by Needs Response</ion-label>
        <ion-radio @click="setConvosSort($event)" slot="start" value="needsResponse"></ion-radio>
      </ion-item>
      <ion-item class="cursor-pointer border-light">
        <ion-label>Sort Newest to Oldest</ion-label>
        <ion-radio @click="setConvosSort($event)" slot="start" value="desc"></ion-radio>
      </ion-item>
      <ion-item class="cursor-pointer">
        <ion-label>Sort Oldest to Newest</ion-label>
        <ion-radio @click="setConvosSort($event)" slot="start" value="asc"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";

export default {
  name: "PopoverSortConvos",
  props: {
    orderBy: String
  },
  methods: {
    async setConvosSort(event) {
      this.orderBy = event.target.value;
      EventBus.$emit("sortConvos", event.target.value);
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0;
}

ion-label {
  font-weight: 500;
}
.border-light {
  border-bottom: 2px solid var(--ion-color-light);
}
</style>
