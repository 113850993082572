<template>
  <ion-grid class="full-height prime-patient-view">
    <ion-row class="full-height">
      <ion-col class="full-height bg-white" size-md="auto" size-xs="12">
        <PrimePage v-if="$isMobile" class="mobile" titleAlignment="left"> </PrimePage>
        <div id="convos-message" v-if="$isMobile" class="mobile convos-message">
          <MessagePanelMain
            v-if="currentPatient"
            v-bind="{
              interactionId,
              activeConversation,
              currentPatient,
              key: interactionId,
              convoChannel,
              showMessageFilters: true
            }"
            :key="'mobile-' + interactionId"
          />
        </div>
        <div class="prime-active-conversations-container column-stretch-wrap full-height">
          <div class="prime-active-conversations-header column-stretch-header">
            <h5>Conversations</h5>
            <span class="valign display-flex">
              <span
                id="search-convos"
                class="form-control-search valign"
                :class="{ focused: focused }"
              >
                <input
                  type="search"
                  class="full-width"
                  v-model="searchConvosString"
                  placeholder="Filter conversations..."
                  @input="debounceSearchConvos($event)"
                  @focus="focused = true"
                  @blur="focused = false"
                />
                <IconSearch v-if="!searchConvosString" id="search-icon" />
                <button v-else @click="clearSearch()" id="clear-btn">
                  <ion-icon id="clear-icon" name="md-close"></ion-icon>
                </button>
              </span>
              <button
                @click="showConvosSortPopover($event)"
                class="prime-button button-clear"
                id="chat-filter-btn"
              >
                <IconFunnel id="chat-filter-icon" class="icon-medium icon-grey" />
              </button>
            </span>
            <ion-segment
              @ionChange="filterConvosByInitiator($event)"
              :value="convoInitiatorType"
              v-if="!isSearching"
              mode="ios"
              class="prime-channel-tabs-container ion-segment-button-small margin-top-ten margin-bottom-five"
            >
              <ion-segment-button
                layout="icon-start"
                value="direct"
                class="prime-channel-tab"
                mode="ios"
                v-tooltip.top-start="{ content: filterConvosTooltip('direct') }"
              >
                Care Team
              </ion-segment-button>
              <ion-segment-button
                layout="icon-start"
                value="system"
                mode="ios"
                v-tooltip.top-start="{ content: filterConvosTooltip('system') }"
              >
                Automated
              </ion-segment-button>
              <div class="tabs-divider"></div>
              <ion-segment-button value="closed" mode="ios" class="prime-channel-tab-all">
                Closed
              </ion-segment-button>
            </ion-segment>
            <!-- <div class="prime-active-conversations-count-container">
              <div class="prime-active-conversations-count-tag" :class="{ zero: convosNeedingResponse == 0 }">
                {{ convosNeedingResponse }}
              </div>
              <div v-if="convosNeedingResponse != 1" class="prime-active-conversations-count-label">
                Conversations Awaiting Response
              </div>
              <div v-if="convosNeedingResponse == 1" class="prime-active-conversations-count-label">
                Conversation Awaiting Response
              </div>
            </div> -->
          </div>
          <!-- DUPLICATE CODE - yuck. Componetize this! -->
          <div
            v-if="isSearching"
            class="column-stretch-body overflow-y-scroll prime-conversation-list-container"
          >
            <div
              v-if="!loadingSearch && searchedConvos.length == 0"
              class="text-center pad-ten full-height"
            >
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <div class="text-center full-width">
                  No messages matched your search
                </div>
              </div>
            </div>
            <div v-else-if="loadingSearch" class="text-center pad-ten full-height">
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
              </div>
            </div>
            <div
              v-else
              :class="{
                'active-list-item': convo.id === activeListItem,
                'prime-system-conversation': convo.initiator == 'system',
                'cursor-pointer': convo.id !== activeListItem,
                'cursor-disabled': isLoadingMessages && convo.id !== activeListItem
              }"
              class="prime-conversation-list-item"
              v-for="(convo, index) in searchedConvos"
              @click="showConvo(convo.id, convo.channel_id, convo.beneficiary_id)"
              :key="index"
            >
              <div
                :class="{
                  'prime-convListItem-needsResponse': convo.needs_response === true
                }"
                class="prime-convListItem-name"
              >
                <span v-dompurify-html="convo.full_name"></span>
              </div>
              <div class="prime-convListItem-time">
                {{ convo.last_interaction | moment("from", "now") }}
              </div>
              <div
                :class="{
                  'prime-convListItem-needsResponse': convo.needs_response === true
                }"
                class="prime-convListItem-title valign"
              >
                <ion-icon
                  v-if="convo.initiator == 'system'"
                  name="md-repeat"
                  v-tooltip.top="'Automated System Conversation'"
                ></ion-icon>
                <IconFlag
                  v-if="convo.urgency == 'emergency'"
                  name="md-repeat"
                  v-tooltip.top="'Conversation is Urgent'"
                  class="is-urgent"
                />
                <span v-dompurify-html="convo.subject"></span>
              </div>
              <div class="prime-convListItem-message">
                {{ convo.body }}
              </div>
            </div>
          </div>
          <div
            v-else
            ref="convoPanel"
            v-on:scroll.passive="scrollListener($event)"
            class="column-stretch-body overflow-y-scroll prime-conversation-list-container"
          >
            <div
              v-if="!convosScrollListening && activeConvosLoading && !isSearching"
              class="text-center pad-ten full-height"
            >
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
              </div>
            </div>
            <div
              v-else-if="activeConvos.length < 1 && !isSearching"
              class="column-stretch-body centerItems text-center text-muted display-flex valign full-height"
            >
              No active conversations
            </div>
            <div
              v-show="activeConvos.length > 0"
              :class="{
                'active-list-item': convo.id === activeListItem,
                'prime-system-conversation': convo.initiator == 'system',
                'cursor-pointer': convo.id !== activeListItem,
                'cursor-disabled': isLoadingMessages && convo.id !== activeListItem
              }"
              class="prime-conversation-list-item"
              v-for="(convo, index) in activeConvos"
              @click="showConvo(convo.id, convo.channel_id, convo.beneficiary_id)"
              :key="index"
            >
              <div
                :class="{
                  'prime-convListItem-needsResponse': convo.needs_response === true
                }"
                class="prime-convListItem-name"
              >
                {{ convo.full_name }}
              </div>
              <div class="prime-convListItem-time">
                {{ convo.last_interaction | moment("from", "now") }}
              </div>
              <div
                :class="{
                  'prime-convListItem-needsResponse': convo.needs_response === true
                }"
                class="prime-convListItem-title valign"
              >
                <ion-icon
                  v-if="convo.initiator == 'system'"
                  name="md-repeat"
                  v-tooltip.top="'Automated System Conversation'"
                ></ion-icon>
                <IconFlag
                  v-if="convo.urgency == 'emergency'"
                  name="md-repeat"
                  v-tooltip.top="'Conversation is Urgent'"
                  class="is-urgent"
                />
                {{ convo.subject }}
              </div>
              <div class="prime-convListItem-message">
                {{ convo.body }}
              </div>
            </div>
            <div
              v-if="convosScrollListening && activeConvosLoading && !beginningOfConvos"
              class="text-center text-muted text-small pad-ten"
            >
              <ion-spinner name="lines" class="spinner-small" color="dark"></ion-spinner>
            </div>
          </div>
          <!--  -->
          <div
            v-if="$can(I.SEND_MESSAGE)"
            class="prime-recent-conversations-footer column-stretch-footer prime-footer-scroll-fade-up"
          >
            <ion-row v-if="$can(I.CAN_ACCESS_BROADCAST_MESSAGING)" class="margin-top-ten">
              <ion-col>
                <button
                  @click="goToNamedRoute('broadcast-message')"
                  class="prime-button button-block button-secondary"
                >
                  <span class="button-inner">
                    Send Broadcast Message
                  </span>
                </button>
              </ion-col>
            </ion-row>
            <ion-row
              v-else
              class="margin-top-ten"
              v-tooltip.top-start="{
                content: $orgMissingPermissions('The Broadcast Messaging feature')
              }"
            >
              <ion-col>
                <button class="prime-button disabled button-block">
                  <span class="button-inner">
                    Send Broadcast Message
                  </span>
                </button>
              </ion-col>
            </ion-row>
            <!-- <div class="button-fw-flow"> -->
            <!-- </div> -->
            <!-- <div class="button-fw-flow">
              <button @click="openModal(newMessageModal, 'Start a New Text Conversation')" class="prime-button button-block button-secondary">
                <span class="button-inner">
                  Send Broadcast Message
                </span>
              </button>
            </div> -->
          </div>
          <div
            v-else
            class="prime-recent-conversations-footer column-stretch-footer prime-footer-scroll-fade-up"
          >
            <ion-row
              class="margin-top-ten"
              v-tooltip.top-start="{
                content: $orgMissingPermissions('The Broadcast Messaging feature')
              }"
            >
              <ion-col>
                <button class="prime-button button-block" disabled>
                  <span class="button-inner">
                    Send Broadcast Message
                  </span>
                </button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </ion-col>
      <ion-col v-if="!$isMobile" class="desktop">
        <PrimePage titleAlignment="left">
          <ion-header role="banner">
            <ion-row
              v-if="
                currentPatient && $route.name === 'patientView' && $route.query.interactionId !== ''
              "
            >
              <ion-col class="margin-top-twenty margin-lr-ten">
                <PatientBanner :currentPatient="currentPatient" :key="currentPatientString" />
              </ion-col>
            </ion-row>
          </ion-header>
          <ion-row class="full-height overflow-hidden">
            <ion-col v-if="currentPatient">
              <ion-row class="full-height">
                <ion-col class="right-divider">
                  <MessagePanelMain
                    v-bind="{
                      interactionId,
                      activeConversation,
                      currentPatient,
                      key: interactionId,
                      convoChannel,
                      showMessageFilters: true
                    }"
                    :key="interactionId"
                  />
                </ion-col>
                <ion-col class="pad-bottom-ten overflow-y-scroll" size="5">
                  <!-- start accordion -->
                  <ion-card
                    color="white"
                    :class="{ 'is-open': activeAccordion == 'tasks' }"
                    class="prime-accordion prime-internal-scoll-card sc-ion-card-md-h sc-ion-card-md-s md ion-color ion-color-white hydrated"
                  >
                    <ion-card-header class="cursor-pointer" @click="toggleAccordion('tasks')">
                      <ion-card-title>
                        <ion-row class="ion-align-items-center">
                          <ion-col size="auto">
                            <h6>Tasks</h6>
                          </ion-col>
                          <ion-col></ion-col>
                          <ion-col size="auto">
                            <ion-icon
                              v-if="activeAccordion == 'tasks'"
                              name="md-remove"
                              color="medium"
                            ></ion-icon>
                            <ion-icon v-else name="ios-add" color="medium"></ion-icon>
                          </ion-col>
                        </ion-row>
                      </ion-card-title>
                    </ion-card-header>
                    <ion-card-content class="padding-death">
                      <div
                        v-if="isTasksLoading"
                        class="text-center pad-ten loading-overlay center-center"
                      >
                        <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
                      </div>
                      <ion-row
                        v-if="tasksList.length == 0"
                        class="ion-align-items-center margin-lr-ten pad-bottom-ten pad-top-ten"
                      >
                        <ion-col class="pad-lr-ten">
                          <p class="text-muted text-center text-bold">No open tasks</p>
                        </ion-col>
                      </ion-row>
                      <ion-row
                        class="ion-align-items-center bottom-divider-thin margin-lr-twenty pad-top-twenty pad-bottom-twenty cursor-pointer"
                        @click="openDescriptionModal(task)"
                        v-for="(task, index) in tasksList.slice(0, 5)"
                        :key="index"
                      >
                        <ion-col size="auto" class="valign">
                          <div
                            class="prime-task-checkbox"
                            :class="{ checked: task.task.status == 'completed' }"
                          >
                            <div />
                          </div>
                        </ion-col>
                        <ion-col class="pad-lr-ten">
                          <div class="text-bold">{{ task.task.name }}</div>
                          <div class="text-small text-muted">
                            {{ task.task.createdAt | moment("from", "now", true) }} old ({{
                              task.task.createdAt | moment("M/D/YYYY")
                            }})
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-card-content>
                    <div class="ion-card-footer prime-footer-scroll-fade-up">
                      <button
                        v-if="$can(I.MODIFY_PATIENT_DATA)"
                        @click="
                          openNewTaskModal('Create a New Task', currentPatient.beneficiary.id)
                        "
                        class="prime-button button-block button-primary"
                      >
                        Create New Task
                      </button>
                      <button
                        v-else
                        class="prime-button disabled button-block button-primary"
                        v-tooltip.top-start="{
                          content: $orgMissingPermissions('The Creating Task feature')
                        }"
                      >
                        Create New Task
                      </button>
                      <button
                        @click="goToPatientTasks"
                        class="prime-button button-block button-secondary margin-top-ten"
                      >
                        View All Tasks
                      </button>
                    </div>
                  </ion-card>
                  <ion-card
                    color="white"
                    :class="{ 'is-open': activeAccordion == 'pastConvos' }"
                    class="prime-accordion prime-internal-scoll-card sc-ion-card-md-h sc-ion-card-md-s md ion-color ion-color-white hydrated"
                  >
                    <ion-card-header class="cursor-pointer" @click="toggleAccordion('pastConvos')">
                      <ion-card-title>
                        <ion-row class="ion-align-items-center">
                          <ion-col size="auto">
                            <h6>Conversation History</h6>
                          </ion-col>
                          <ion-col></ion-col>
                          <ion-col size="auto">
                            <ion-icon
                              v-if="activeAccordion == 'pastConvos'"
                              name="md-remove"
                              color="medium"
                            ></ion-icon>
                            <ion-icon v-else name="ios-add" color="medium"></ion-icon>
                          </ion-col>
                        </ion-row>
                      </ion-card-title>
                    </ion-card-header>
                    <ion-card-content
                      v-if="recentConvos"
                      class="pad-ten scroll-shrink prime-rh-accordion-card rh-accordion-open"
                    >
                      <ion-card
                        color="light"
                        v-for="(convo, key) in recentConvos.filter(v => v.status === 'closed')"
                        :key="key"
                      >
                        <div
                          class="prime-past-conversation-container"
                          @click="openModal(viewMessageModal, convo.description, convo.id)"
                        >
                          <div class="prime-past-conversation-icon">
                            <IconChatBubble class="icon-small" />
                          </div>
                          <div class="prime-past-conversation-content">
                            <div class="prime-past-conversation-title">
                              {{ convo.description }}
                            </div>
                            <div class="prime-past-conversation-time">
                              Last interaction
                              {{ convo.last_message_date | moment("from", "now") }} ({{
                                convo.last_message_date | moment("M/D/YYYY")
                              }})
                            </div>
                          </div>
                        </div>
                      </ion-card>
                      <div class="spacer"></div>
                    </ion-card-content>
                    <div
                      class="ion-card-footer ion-card-footer-empty prime-footer-scroll-fade-up"
                    ></div>
                  </ion-card>

                  <ion-card
                    color="white"
                    :class="{ 'is-open': activeAccordion == 'recentImages' }"
                    class="prime-accordion prime-internal-scoll-card sc-ion-card-md-h sc-ion-card-md-s md ion-color ion-color-white hydrated"
                  >
                    <ion-card-header
                      class="cursor-pointer"
                      @click="toggleAccordion('recentImages')"
                    >
                      <ion-card-title>
                        <ion-row class="ion-align-items-center">
                          <ion-col size="auto">
                            <h6>Images</h6>
                          </ion-col>
                          <ion-col></ion-col>
                          <ion-col size="auto">
                            <ion-icon
                              v-if="activeAccordion == 'recentImages'"
                              name="md-remove"
                              color="medium"
                            ></ion-icon>
                            <ion-icon v-else name="ios-add" color="medium"></ion-icon>
                          </ion-col>
                        </ion-row>
                      </ion-card-title>
                    </ion-card-header>
                    <ion-card-content
                      class="scroll-shrink prime-rh-accordion-card rh-accordion-open"
                    >
                      <div
                        v-if="isGettingRecentImages"
                        class="centerItems text-center display-flex valign full-height margin-top-twenty full-width"
                      >
                        <span>
                          <ion-spinner
                            name="lines"
                            class="spinner-large"
                            color="medium"
                          ></ion-spinner>
                        </span>
                      </div>
                      <div v-else-if="!isGettingRecentImages && recentImages.length > 0">
                        <ion-card color="light" v-for="image in recentImages" :key="image.date">
                          <ion-row class="ion-align-items-center pad-ten">
                            <ion-col class="cursor-pointer" size="auto" @click="showImage(image)">
                              <div class="img-hidden-wrap display-flex centerItems">
                                <IconEyeHidden class="icon-eye-sensitive" v-if="image.hidden" />
                                <img
                                  @error="setBrokenImg"
                                  v-else
                                  class="recent-image-thumbnail"
                                  :src="image.thumbPath ? image.thumbPath : image.url"
                                />
                              </div>
                            </ion-col>
                            <ion-col
                              @click="showImage(image)"
                              class="pad-left-twenty cursor-pointer"
                            >
                              <div>
                                Sent <TimeSince :startdate="image.createdAt"></TimeSince> ago ({{
                                  image.createdAt | moment("M/D/YYYY")
                                }})
                              </div>
                            </ion-col>
                            <ion-col v-if="image.url" size="auto">
                              <button
                                @click="showActions($event, image)"
                                class="prime-button button-clear button-secondary muted"
                              >
                                <ion-icon name="md-more"></ion-icon>
                              </button>
                            </ion-col>
                          </ion-row>
                        </ion-card>
                        <div class="spacer"></div>
                      </div>
                      <div
                        v-else
                        class="text-center text-muted full-width full-height center-center"
                      >
                        There are no recent images to view
                      </div>
                    </ion-card-content>
                    <div
                      class="ion-card-footer ion-card-footer-empty prime-footer-scroll-fade-up"
                    ></div>
                  </ion-card>
                  <!-- end accordion -->
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col v-else>
              <div class="center-center flex-column full-height margin-top-twenty full-width">
                <div
                  @mouseenter="hoverTrigger()"
                  @mouseleave="clearHoverTrigger()"
                  @click="beginEaster()"
                  class="fade-in"
                  :class="showHoverTrigger ? 'cursor-pointer' : ''"
                >
                  <LogoWatermarkToColor
                    v-if="showHoverTrigger"
                    class="prime-patientview-watermark pad-lr-twenty"
                  />
                  <LogoWatermark v-else class="prime-patientview-watermark pad-lr-twenty" />
                </div>
                <div v-if="showHoverTrigger" class="fade-in text-muted">
                  Click to view a tribute to our founder, Dr. Tom Doerr
                </div>
              </div>
              <div v-if="itsEaster">
                <EasterEgg class="fade-in" />
              </div>
            </ion-col>
          </ion-row>
        </PrimePage>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import BrokenImg from "@/assets/Broken-Image-Icon-Only.png";
import MessagePanelMain from "@/components/Chat/MessagePanelMain";
import ModalImage from "@/components/Chat/ModalImage";
import ModalNewMessage from "@/components/Chat/ModalNewMessage";
import ModalViewConvo from "@/components/Chat/ModalViewConvo";
import PopoverSortConvos from "@/components/Chat/PopoverSortConvos.vue";
import EasterEgg from "@/components/Global/EasterEgg";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconEyeHidden from "@/components/Global/Icons/IconEyeHidden";
import IconFlag from "@/components/Global/Icons/IconFlag";
import IconFunnel from "@/components/Global/Icons/IconFunnel";
import LogoWatermark from "@/components/Global/Logos/LogoWatermark";
import LogoWatermarkToColor from "@/components/Global/Logos/LogoWatermarkToColor";
import PrimePage from "@/components/Global/PrimePage";
import TimeSince from "@/components/Global/TimeSince";
import PopoverImageOptions from "@/components/Roster/PopoverImageOptions.vue";
import PatientBanner from "@/components/Settings/Patient/PatientBanner";
import ModalNewTask from "@/components/Tasks/ModalNewTask";
import ModalTaskDescription from "@/components/Tasks/ModalTaskDescription";
import { send as httpSend } from "@/services/Api";
import getActiveConvos from "@/services/Api/getActiveConvos";
import GetTasksList from "@/services/Api/getTasksList";
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import {
  add,
  arrowDown,
  arrowUp,
  call,
  chatboxes,
  close,
  remove,
  repeat,
  text,
  warning
} from "ionicons/icons";
import _ from "lodash";
import IconSearch from "../Global/Icons/IconSearch.vue";

addIcons({
  "ios-add": add.ios,
  "md-add": add.md,
  "md-chatboxes": chatboxes.md,
  "ios-chatboxes": chatboxes.ios,
  "ios-arrow-up": arrowUp.ios,
  "ios-arrow-down": arrowDown.ios,
  "md-remove": remove.md,
  "md-arrow-up": arrowUp.md,
  "md-call": call.md,
  "md-text": text.md,
  "ios-warning": warning.ios,
  "md-repeat": repeat.md,
  "md-close": close.md
});

export default {
  name: "ChatProfessional",
  components: {
    PrimePage,
    MessagePanelMain,
    IconChatBubble,
    IconEyeHidden,
    IconSearch,
    IconFunnel,
    LogoWatermark,
    LogoWatermarkToColor,
    EasterEgg,
    IconFlag,
    TimeSince,
    PatientBanner
  },
  data() {
    return {
      healthTimelineResponse: undefined,
      scrollBuffer: 50,
      currentPatient: null,
      patientHistory: 0,
      outboundMessage: "",
      isMessageSending: false,
      isPatientLoading: false,
      isTasksLoading: true,
      recentConvos: undefined,
      isLoadingMessages: false,
      recentImages: [],
      isGettingRecentImages: true,
      interactionId: parseInt(this.$route.query.interaction_id),
      patientId: parseInt(this.$route.query.patient),
      activeConversation: undefined,
      selectedInteractionId: undefined,
      convoChannel: "sms",
      newMessageModal: ModalNewMessage,
      viewMessageModal: ModalViewConvo,
      activeListItem: undefined,
      convoEvent: document.config.events.beneficiary,
      eggTimer: undefined,
      showHoverTrigger: false,
      itsEaster: false,
      isOpen: true,
      activeAccordion: undefined,
      tasksList: [],
      searchConvosString: undefined,
      isSearching: false,
      loadingSearch: true,
      searchedConvos: [],
      focused: false,
      serverParams: {
        id: "0",
        page: "1",
        perPage: "100",
        columnFilters: {
          status: "created,assigned,in_progress",
          search: null,
          program: "",
          organization: "",
          provider: "",
          assignedTo: "",
          vip: "",
          beneficiary: undefined
        },
        sort: []
      },
      slideType: ""
    };
  },
  watch: {
    // interactionId: function() {
    //   if (this.interactionId) {
    //     this.getMessagesByConvo(this.interactionId);
    //     this.activeListItem = this.interactionId;
    //   }
    // },
    patientId: function() {
      if (this.patientId) {
        this.getCurrentPatient(this.patientId);
      }
    },
    ordIdState() {
      this.$store.dispatch("websocket/subscribe", this.subscription);
    }
  },
  computed: {
    currentPatientString() {
      return JSON.stringify(this.currentPatient);
    },
    ordIdState() {
      return this.$store.getters["organizations/getOrganizationId"];
    },
    activeConvos() {
      return this.$store.getters["chatProfessional/activeConvos"];
    },
    convosCounts() {
      return this.$store.getters["chatProfessional/convosCounts"];
    },
    pageNumber() {
      return this.$store.getters["chatProfessional/convosPageNum"];
    },
    convoInitiatorType() {
      return this.$store.getters["chatProfessional/convoInitiatorType"];
    },
    convosOrderby() {
      return this.$store.getters["chatProfessional/convosOrderby"];
    },
    activeConvosLoading() {
      return this.$store.getters["chatProfessional/activeConvosLoading"];
    },
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    subscription() {
      return this.ordIdState ? { type: "organization", data: Number(this.ordIdState) } : {};
    },
    interactionSubscription() {
      return { type: "interaction", data: this.interactionId };
    },
    convosNeedingResponse() {
      return this.activeConvos.filter(function(convos) {
        return convos.should_respond === true;
      }).length;
    },
    beginningOfConvos() {
      return this.$store.getters["chatProfessional/beginningOfConvos"];
    },
    convosScrollListening() {
      return this.$store.getters["chatProfessional/convosScrollListening"];
    }
  },
  created() {
    EventBus.$on("sortConvos", evt => {
      this.sortConvos(evt);
    });
    EventBus.$on("selectedPatientId", id => {
      if (id) {
        this.getCurrentPatient(id, true);
      }
    });
    EventBus.$on("getTasks", () => {
      this.getTasks(this.serverParams);
    });
    EventBus.$on("refresh-recent-images", () => {
      this.getRecentImages(this.currentPatient.account.id, false);
    });

    this.$store.dispatch("chatProfessional/convoInitiatorType", "direct");
    this.$store.dispatch("chatProfessional/getPartialActiveConvos");
    this.$store.dispatch("chatProfessional/getConvosCounts");
    this.$store.commit("chatProfessional/enableConvosLoader");
    this.$store.commit("chatProfessional/turnConvosScrollListeningOff");

    if (this.ordIdState) {
      this.$store.dispatch("websocket/subscribe", this.subscription);
    }

    if (this.$route.query.interactionId) {
      this.interactionId = parseInt(this.$route.query.interactionId);
      this.selectedInteractionId = this.interactionId;
      this.searchConvosString = this.$route.query.interactionId;
      this.searchConversations();
    }

    if (this.patientId) {
      this.getCurrentPatient(this.patientId, true);
    }

    // if (this.interactionId) {
    //   this.getMessagesByConvo(this.interactionId);
    //   this.activeListItem = this.interactionId;
    // }

    EventBus.$on("newInteraction", (interactionId, newMessageChannel) => {
      this.$store.commit("chatProfessional/toggleNewInteraction");
      if (this.currentPatient.beneficiary.id) {
        this.showConvo(interactionId, newMessageChannel, this.currentPatient.beneficiary.id);
      }
      this.activeListItem = interactionId;
      this.convoChannel = newMessageChannel;
    });

    EventBus.$on("endEaster", () => {
      this.itsEaster = false;
    });

    EventBus.$on("startSecureChat", () => {
      this.convoChannel = "zeus";
    });

    EventBus.$on("conversationUpdated", (interactionId, newMessageChannel) => {
      this.$store.dispatch("chatProfessional/updateCurrentActiveConvos");
      if (this.currentPatient.beneficiary.id) {
        this.showConvo(interactionId, newMessageChannel, this.currentPatient.beneficiary.id);
      }
      this.activeListItem = interactionId;
      this.convoChannel = newMessageChannel;
    });

    EventBus.$on("conversationClosed", (interactionId, beneficiaryId) => {
      history.pushState(null, null, this.$route.path + "?patient=" + beneficiaryId);
      this.$store.dispatch("chatProfessional/updateCurrentActiveConvos");
      this.getCurrentPatient(beneficiaryId, true);
      this.getRecentConvos(beneficiaryId);
    });
  },
  beforeMount() {
    window.addEventListener("popstate", () => {
      this.$store.commit("setPanelFlow", {
        prevPanel: ".prime-active-conversations-container",
        currentPanel: "#convos-message"
      });
    });
  },
  methods: {
    setBrokenImg(event) {
      event.target.src = BrokenImg;
    },
    showActions(ev, image) {
      this.$ionic.popoverController
        .create({
          component: PopoverImageOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: { image }
          }
        })
        .then(p => p.present());
    },
    goToNamedRoute(routeName) {
      this.$router.push({
        name: routeName
      });
    },
    goToPatientTasks: function() {
      this.$router.push({
        path: "/patient-settings/tasks/" + this.currentPatient.beneficiary.id
      });
    },
    openDescriptionModal: async function(task) {
      this.$ionic.modalController
        .create({
          component: ModalTaskDescription,
          cssClass: "prime-modal-xwide",
          mode: "ios",
          componentProps: {
            propsData: {
              taskId: task.task.id
            }
          }
        })
        .then(m => m.present());
    },
    openNewTaskModal(title, beneficiaryId) {
      this.$ionic.modalController
        .create({
          component: ModalNewTask,
          mode: "ios",
          componentProps: {
            propsData: {
              title: title,
              beneficiaryId: beneficiaryId
            }
          }
        })
        .then(m => m.present());
    },
    async getTasks(params) {
      this.isTasksLoading = true;
      try {
        const fullPayload = await GetTasksList(params);
        this.tasksList = fullPayload.results;
      } finally {
        this.isTasksLoading = false;
      }
    },
    toggleAccordion: function(which) {
      if (this.activeAccordion == which) {
        this.activeAccordion = undefined;
      } else {
        this.activeAccordion = which;
        switch (which) {
          case "tasks":
            if (!(this.patientHistory & 1)) {
              this.patientHistory += 1;
              this.getTasks(this.serverParams);
            }
            break;
          case "pastConvos":
            if (!(this.patientHistory & 2)) {
              this.patientHistory += 2;
              this.getRecentConvos(this.serverParams.columnFilters.beneficiary);
            }
            break;
          case "recentImages":
            if (!(this.patientHistory & 4)) {
              this.patientHistory += 4;
              this.getRecentImages(this.currentPatient.account.id);
            }
            break;
        }
      }
    },
    scrollListener(event) {
      if (!this.activeConvosLoading && !this.beginningOfConvos) {
        this.$store.commit("chatProfessional/turnConvosScrollListeningOn");

        if (
          event.target.scrollHeight - this.scrollBuffer <
            Math.ceil(event.target.scrollTop + event.target.clientHeight) &&
          !this.beginningOfConvos
        ) {
          this.$store.commit("chatProfessional/enableConvosLoader");
          this.debounceScrollListener(event);
          this.scrollHeight = event.target.scrollHeight;
        }
      }
    },
    debounceScrollListener: _.debounce(function() {
      this.$store.dispatch("chatProfessional/convosPageNum", this.pageNumber + 1);
      this.$store.dispatch("chatProfessional/getPartialActiveConvos");
    }, 400),

    debounceSearchConvos: _.debounce(function() {
      this.selectedInteractionId = undefined;
      this.searchConversations();
    }, 400),
    async searchConversations() {
      // keeps selected message panel visible after searching
      if (this.searchConvosString.length > 0) {
        this.loadingSearch = true;
        this.isSearching = true;
        this.searchedConvos = await getActiveConvos(
          undefined,
          undefined,
          50,
          this.convosOrderby,
          this.searchConvosString,
          this.selectedInteractionId
        );
        this.loadingSearch = false;
      } else {
        this.isSearching = false;
        this.$store.commit("chatProfessional/enableConvosLoader");
        this.$store.commit("chatProfessional/turnConvosScrollListeningOff");
        this.$store.dispatch("chatProfessional/convoInitiatorType", this.convoInitiatorType);
        this.$store.dispatch("chatProfessional/getPartialActiveConvos");
      }
    },
    clearSearch() {
      history.pushState(null, null, this.$route.path);
      this.searchConvosString = "";
      this.searchConversations();
      document.querySelector("#search-convos input").focus();
    },
    sortConvos(evt) {
      this.$store.dispatch("chatProfessional/convosOrderby", evt);
      if (this.isSearching) {
        this.searchConversations();
      } else {
        // reset scroll position
        const convoPanel = this.$refs.convoPanel;
        if (convoPanel) {
          convoPanel.scrollTop = 0;
        }
        this.$store.commit("chatProfessional/enableConvosLoader");
        this.$store.commit("chatProfessional/turnConvosScrollListeningOff");
        this.$store.dispatch("chatProfessional/updateCurrentActiveConvos");
      }
    },
    showConvosSortPopover(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverSortConvos,
          event: ev,
          cssClass: "popover-sort-convos",
          mode: "ios",
          componentProps: {
            propsData: {
              orderBy: this.convosOrderby
            }
          }
        })
        .then(p => p.present());
    },
    filterConvosByInitiator(evt) {
      // reset scroll position
      const convoPanel = this.$refs.convoPanel;

      if (convoPanel) {
        convoPanel.scrollTop = 0;
      }
      this.$store.commit("chatProfessional/enableConvosLoader");
      this.$store.commit("chatProfessional/turnConvosScrollListeningOff");
      this.$store.dispatch("chatProfessional/convoInitiatorType", evt.target.value);
      this.$store.dispatch("chatProfessional/updateCurrentActiveConvos");
    },
    filterConvosTooltip(type) {
      let totalRecords =
        type == "direct" ? this.convosCounts.direct.totalRecords : this.convosCounts.system;
      let awaitingResponse = type == "direct" ? this.convosCounts.direct.awaitingResponse : "";

      if (type == "direct") {
        return `
          <div class="pad-lr-fifteen pad-bottom-five">
          <p class="margin-bottom-two margin-top-five fw-5">Open Conversations:&nbsp;</p><b class="text-med">${totalRecords}</b>
          <p class="margin-bottom-zero margin-top-five fw-5">Needs Response:</p><b class="text-med">${awaitingResponse}</b>
          </div>
          `;
      } else if (type == "system") {
        return `
          <div class="pad-lr-fifteen pad-bottom-five">
          <p class="margin-bottom-two margin-top-five fw-5">Open Conversations:&nbsp;</p><b class="text-med">${totalRecords}</b>
          </div>
          `;
      }
    },
    getRecentImages(accountId, showLoader = true) {
      showLoader ? (this.isGettingRecentImages = true) : "";
      const method = "post";
      const path = document.config.convoAttachments + "info";
      const payload = {
        accountId: accountId
      };

      httpSend({ path, payload, method, authToken: true })
        .then(result => {
          this.recentImages = result.data.results;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get Recent Images. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isGettingRecentImages = false;
        });
    },
    getRecentConvos: async function(id) {
      const method = "get";
      const path = document.config.recentConvosApi + id;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.recentConvos = result.data;
        })
        .catch(error => {
          this.$store.commit("chatProfessional/clearMessages");
          this.$ionic.toastController
            .create({
              header: "Could not get Recent Conversations. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async showConvo(interactionId, convoChannel, patientId) {
      //close previous accordion
      this.activeAccordion = undefined;
      this.tasksList, this.recentImages, (this.recentConvos = []);
      this.patientHistory = 0;

      // check to make sure a click is allowed
      if (
        !this.isLoadingMessages &&
        (interactionId !== this.activeListItem || window.innerWidth < 768)
      ) {
        await this.getCurrentPatient(patientId);
        await this.getMessagesByConvo(interactionId);
        this.activeListItem = interactionId;
        this.interactionId = interactionId;
        this.convoChannel = convoChannel;

        const params = { interactionId: this.interactionId };
        this.$route.query.interactionId = this.interactionId;
        history.pushState(
          { query: params },
          null,
          this.$route.path + "?interactionId=" + this.interactionId
        );
        this.$route.query.interactionId = this.interactionId;
        if (this.currentPatient) {
          this.$store.commit("setPanelFlow", {
            prevPanel: ".prime-active-conversations-container",
            currentPanel: "#convos-message"
          });
          this.$mobilize(".prime-active-conversations-container", "#convos-message", "mobile-show");
        }
      }
    },
    getMessagesByConvo: async function(id) {
      this.isLoadingMessages = true;
      const method = "get";
      const path = document.config.messageApi + id + "/messages";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.interactionId = id;

          this.serverParams.columnFilters.beneficiary = result.data.beneficiary_id;
          this.isLoadingMessages = false;
        })
        .catch(error => {
          this.isLoadingMessages = false;
          khanSolo.log(error);
        });
    },
    getMessagesByPatient: function(id) {
      this.isLoadingMessages = true;
      const method = "get";
      const path = document.config.messageApi + id + "/messages";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.interactionId = id;
          this.serverParams.columnFilters.beneficiary = result.data.beneficiary_id;
          this.isLoadingMessages = false;
        })
        .catch(error => {
          this.isLoadingMessages = false;
          khanSolo.log(error);
        });
    },
    getCurrentPatient: async function(id, getMessagesToo) {
      const method = "get";
      const path = document.config.patientSettings + id;

      await httpSend({ path, method, authToken: true })
        .then(result => {
          this.currentPatient = result.data.payload;
          this.activeConversation = result.data.payload.activeConversation;

          if (!result.data.payload.interactionId) {
            this.$store.commit("chatProfessional/clearMessages");
            this.$store.commit("chatProfessional/clearMessagesPayload");
            this.interactionId = undefined;
            this.activeListItem = undefined;
          } else if (getMessagesToo) {
            this.activeListItem = result.data.payload.interactionId;
            this.getMessagesByPatient(result.data.payload.interactionId);
          }
          this.$store.commit("chatProfessional/setCurrentPatient", this.currentPatient);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get current patient. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    openModal(componentName, modalTitle, id) {
      this.$ionic.modalController
        .create({
          component: componentName,
          mode: "ios",
          componentProps: {
            data: {
              //currentpatient: this.currentPatient,
            },
            propsData: {
              title: modalTitle,
              interactionId: id || null,
              currentPatient: this.currentPatient
            }
          }
        })
        .then(m => m.present());
    },
    showImage(image) {
      this.$ionic.modalController
        .create({
          component: ModalImage,
          mode: "ios",
          cssClass: "prime-modal-fullscreen darkmode",
          componentProps: {
            propsData: {
              id: image.id,
              url: image.url,
              isHidden: image.hidden
            }
          }
        })
        .then(m => {
          m.present();
        });
    },
    hoverTrigger() {
      this.eggTimer = window.setTimeout(() => {
        this.showHoverTrigger = true;
      }, 8000);
    },
    clearHoverTrigger() {
      this.showHoverTrigger = false;
      window.clearTimeout(this.eggTimer);
    },
    beginEaster() {
      if (this.showHoverTrigger) {
        this.itsEaster = true;
      }
    }
  },
  beforeDestroy: function() {
    this.$store.dispatch("websocket/unsubscribe", this.subscription);
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
    this.$store.commit("chatProfessional/setCurrentPatient", {});
  }
};
</script>

<style scoped>
/* Conversations side bar search and sort stuff */
#search-convos {
  padding: 4px 0 4px 8px;
  margin-bottom: 1px;
  flex-grow: 1;
  border: 2px solid var(--ion-color-white-shade);
}
#search-icon {
  margin-right: 5px;
  fill: #aaacae;
  width: 30px;
  height: 30px;
}
#search-convos ::placeholder {
  color: #a7a9af;
}
#clear-btn {
  border: none;
  background-color: transparent;
  vertical-align: middle;
  outline: 0;
  cursor: default;
  padding: 0;
  margin-right: 5px;
  height: 30px;
}
#clear-icon {
  color: var(--ion-color-medium);
  width: 26px;
  height: 26px;
}
#clear-icon:hover {
  color: var(--ion-color-medium-shade);
}
#search-convos:hover {
  border: 2px solid var(--ion-color-medium);
}
.focused {
  border: 2px solid var(--ion-color-medium) !important;
}
#chat-filter-icon {
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 5px;
}
#chat-filter-btn {
  margin-left: 3px;
  margin-right: -9px;
  padding: 7px 7px 0 7px;
}

.is-urgent {
  fill: var(--ion-color-danger);
  max-width: 17px;
  min-width: 17px;
}
/* active list item styles */
.prime-conversation-list-container::before {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  top: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item {
  padding: 15px 20px 15px 40px;
  position: relative;
  font-weight: 400;
}

.prime-conversation-list-item:after {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item.active-list-item:after {
  display: none;
}

.prime-conversation-list-item .prime-system-conversation-indicator {
  content: "";
  position: absolute;
  background: var(--color-secondary-button-shade);
  top: 15px;
  bottom: 15px;
  left: 20px;
  width: 5px;
  overflow: hidden;
}

.prime-conversation-list-item .prime-system-conversation-indicator:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 1;
  animation: slideY 3s ease-in-out infinite alternate;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 89%,
    rgba(125, 185, 232, 0) 100%
  );
}

.prime-convListItem-name {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.prime-convListItem-name.prime-convListItem-needsResponse {
  font-weight: 700;
}

.prime-convListItem-name.prime-convListItem-needsResponse:before {
  content: "";
  position: absolute;
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  left: 20px;
  top: 19px;
}

.prime-convListItem-time {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 10px;
  color: var(--ion-color-medium-shade);
}

.prime-convListItem-title {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-convListItem-title .prime-convListItem-needsResponse {
  font-weight: 500;
}

.prime-convListItem-title ion-icon {
  vertical-align: middle;
}

.prime-convListItem-message {
  font-size: 12px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-conversation-list-item.active-list-item {
  background-color: var(--color-secondary-button);
  border-right: 2px solid var(--ion-color-primary);
}

.prime-conversation-list-item .prime-convListItem-name.prime-convListItem-needsResponse {
  font-weight: 600;
}
ion-segment-button.segment-button-checked {
  background-color: white;
  color: #0f5db8;
}
.prime-patientview-watermark {
  width: 550px;
}
.prime-active-conversations-count-container {
  padding: 10px;
  background: var(--ion-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
}
.prime-active-conversations-count-tag {
  background: var(--ion-color-primary);
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 6px;
  border-radius: 8px;
  margin-right: 10px;
}
.prime-active-conversations-count-tag.zero {
  background: var(--ion-color-medium);
}
.prime-active-conversations-count-label {
  font-size: 12px;
  font-weight: 600;
}

/* end active list item styles */

ion-label {
  overflow: unset !important;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
}

.column-stretch-footer {
  flex: 0 0 auto;
}

ion-card ion-card {
  padding: 0;
  border-radius: 6px !important;
  margin-left: -10px;
  margin-right: -10px;
}

ion-card-content.prime-minimal-list {
  padding: 0 0 0 10px;
  margin-right: -10px;
}

.prime-patient-view ion-row.prime-full-height-row {
  height: calc(100vh - 165px) !important;
}

.prime-patient-view ion-row ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prime-patient-view ion-col ion-card:first-child {
  margin-top: 20px;
}

.prime-patient-view ion-col ion-card:last-child {
  margin-bottom: 0;
}

.prime-patient-view ion-card {
  flex-shrink: 0;
}

.prime-patient-view ion-card.scroll-shrink {
  flex-shrink: 1;
  flex-grow: 1;
}

.prime-patient-view ion-card-header {
  padding: 10px 10px 10px 20px;
}

.prime-internal-scoll-card ion-card-content {
  overflow: auto;
}

.prime-internal-scoll-card ion-card {
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
}

.prime-internal-scoll-card {
  display: flex;
  flex-direction: column;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.prime-recent-conversations-footer {
  height: relative;
  padding: 0 20px 10px 20px;
  background: var(--ion-color-white);
}

.prime-recent-conversations-footer .button-fw-flow {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.prime-recent-conversations-footer button {
  width: 100%;
  margin: 0;
}

.prime-patient-data-feed-card ion-card ion-card-title {
  font-size: 16px;
  font-weight: 600;
}

.prime-patient-data-feed-card ion-card ion-card-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium) !important;
  margin-top: 0;
}

.prime-patient-data-feed-card ion-card ion-card-header {
  padding-bottom: 0;
}

/* Right-Hand Accordion */
.prime-rh-accordion ion-card-header {
  display: flex;
  cursor: pointer;
}

.prime-rh-accordion ion-card-title {
  flex-grow: 1;
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls {
  font-size: 20px;
  margin-bottom: -5px;
  color: var(--ion-color-medium);
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls ion-icon.closed {
  margin-right: -20px;
}

/* Past Conversations Panel */
.prime-past-conversation-container {
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
}

.prime-past-conversation-icon {
  padding-top: 3px;
  padding-right: 15px;
}

.prime-past-conversation-content {
  overflow: hidden;
}
.prime-past-conversation-title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-past-conversation-time {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* the easter egg */
.easter-time {
  cursor: pointer;
}

.message {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

/* Task Checkbox ---------------- */
.prime-task-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-task-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-task-checkbox div::before,
.prime-task-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-task-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-task-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-task-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-task-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}

/* Accordion ---------------- */
ion-card.prime-accordion ion-card-header {
  padding: 1px 10px 1px 20px;
}

ion-card.prime-accordion {
  flex: 0 1 auto;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0px;
}

ion-card.prime-accordion:first-child,
ion-card.prime-accordion {
  margin-top: 10px !important;
}

ion-card.prime-accordion ion-card-content {
  margin-top: 10px;
  padding-top: 5px;
  overflow-y: auto;
  flex-grow: 1;
  display: none;
}

ion-card.prime-accordion .ion-card-footer {
  margin: 0 -10px -10px -10px;
  padding: 20px;
  display: none;
}

ion-card.prime-accordion .ion-card-footer-empty {
  padding: 5px;
}

ion-card.prime-accordion.is-open {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 300px;
}

ion-card.prime-accordion.is-open ion-card-content,
ion-card.prime-accordion.is-open .ion-card-footer {
  display: initial;
}

.prime-patient-data-feed-card {
  margin-bottom: 0px;
}

.recent-image-thumbnail {
  width: 100px;
  border-radius: 8px;
}
.img-hidden-wrap {
  text-align: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #dadada;
  border-radius: 6px;
}
.icon-eye-sensitive {
  fill: var(--ion-color-dark-tint);
  width: 40px;
}
.convos-message {
  margin-top: 64px;
  background-color: white;
  display: none;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.mobile >>> .prime-row-container {
  padding: 0;
  height: 0;
}
</style>
