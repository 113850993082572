import { render, staticRenderFns } from "./PopoverSortConvos.vue?vue&type=template&id=55058854&scoped=true"
import script from "./PopoverSortConvos.vue?vue&type=script&lang=js"
export * from "./PopoverSortConvos.vue?vue&type=script&lang=js"
import style0 from "./PopoverSortConvos.vue?vue&type=style&index=0&id=55058854&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55058854",
  null
  
)

export default component.exports