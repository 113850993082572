var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-page',{staticClass:"ion-page",attrs:{"main":""}},[_c('ion-header',[(_vm.showMessageFilters)?_c('ion-grid',{staticClass:"active-conversation-subheader-container"},[_c('ion-row',[_c('ion-col',[_c('div',[_c('PatientBanner',{staticClass:"mobile patient-banner-mobile",attrs:{"currentPatient":_vm.currentPatient}})],1)])],1),_c('ion-row',{staticClass:"ion-align-items-center"},[_c('ion-col',[_c('ion-segment',{staticClass:"prime-channel-tabs-container",attrs:{"value":_vm.msgChannel,"mode":"ios"},on:{"ionChange":function($event){return _vm.changeChannel($event)}}},[_c('ion-segment-button',{staticClass:"prime-channel-tab",attrs:{"layout":"icon-start","value":"sms","mode":"ios"}},[_c('span',{class:{
                  'needs-response-badge': _vm.needsResponseTab == 'sms'
                }}),(!_vm.$isMobile)?_c('IconChatBubble',{staticClass:"icon-small"}):_vm._e(),_vm._v(" Text Message ")],1),_c('ion-segment-button',{attrs:{"layout":"icon-start","value":"zeus","mode":"ios"}},[_c('span',{class:{
                  'needs-response-badge': _vm.needsResponseTab == 'zeus'
                }}),(!_vm.$isMobile)?_c('IconLock',{staticClass:"icon-small"}):_vm._e(),_vm._v(" Secure Chat ")],1),_c('div',{staticClass:"prime-channel-tab-separator"}),_c('ion-segment-button',{staticClass:"prime-channel-tab-all",attrs:{"value":"all","mode":"ios"}},[_vm._v(" All ")])],1)],1),_c('ion-col',{attrs:{"size":"auto"}},[(_vm.interactionId)?_c('MessageOptions',_vm._b({},'MessageOptions',{
              interactionId: _vm.interactionId,
              msgChannel: _vm.msgChannel
            },false)):_vm._e()],1)],1)],1):_vm._e()],1),_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
      always: false,
      smooth: false,
      scrollonremoved: true
    }),expression:"{\n      always: false,\n      smooth: false,\n      scrollonremoved: true\n    }"}],ref:"messagePanel",staticClass:"message-panel-content column-stretch-body",attrs:{"id":"message-panel-content"}},[_c('div',{staticClass:"messages-wrapper full-height"},[(_vm.loading)?_c('div',{staticClass:"text-center pad-ten loading-overlay center-center"},[_c('ion-spinner',{staticClass:"spinner-large",attrs:{"name":"lines","color":"dark"}})],1):_vm._e(),_vm._l((_vm.filteredMessages),function(message){return _c('ChatBubble',_vm._b({key:message.id},'ChatBubble',{
          message
        },false))}),(_vm.status == 'closed')?_c('div',{staticClass:"text-muted full-width text-center"},[(_vm.status == 'closed')?_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.convoClosed))])]):_vm._e()]):_vm._e(),(_vm.initiator == 'system' && _vm.status == 'active')?_c('div',{staticClass:"text-muted full-width text-center"},[_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.systemMessage))])])]):_vm._e(),(_vm.status == 'initiated')?_c('div',{staticClass:"text-muted full-width text-center"},[_c('div',[_c('small',[_vm._v(_vm._s(_vm.pageCopy.notifications.initiatedMessage))])])]):_vm._e(),(_vm.filteredMessages.length == 0)?_c('div',{staticClass:"text-muted full-width full-height display-flex valign"},[_c('div',{staticClass:"text-center full-width"},[_vm._v(" There are no messages to display at this time ")])]):_vm._e()],2)]),_c('ion-footer',{staticClass:"column-stretch-footer prime-conv-message-entry-container",attrs:{"mode":"ios"}},[_c('ion-toolbar',{staticClass:"mobile-spcss"},[(_vm.$can(_vm.I.SEND_MESSAGE) && _vm.showMessageInput)?_c('MessageInput',_vm._b({},'MessageInput',{
          interactionId: _vm.interactionId,
          beneficiaryId: _vm.beneficiaryId,
          accountId: _vm.accountId,
          copy: _vm.pageCopy.messageInput,
          msgChannel: _vm.msgChannel,
          activeConversation: _vm.activeConversation
        },false)):_vm._e(),_c('MessageControls',_vm._b({},'MessageControls',{
          messageTitle: _vm.messageTitle,
          accountId: _vm.accountId,
          interactionId: _vm.interactionId,
          initiator: _vm.initiator,
          status: _vm.status,
          msgChannel: _vm.msgChannel,
          beneficiaryId: _vm.beneficiaryId,
          urgency: _vm.urgency,
          filteredMessages: _vm.filteredMessages,
          programId: _vm.programId
        },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }